<template>
<div class="wrapper">
    <div class="o o1">
        <div class="e" />
    </div>
    <div class="o o2">
        <div class="e" />
    </div>
    <div class="o o3">
        <div class="e" />
    </div>
</div>
</template>

<style scoped>
/* orbit and electron colors */
.o {
    border-color: gray;
}

.o1>.e:after {
    background: darkgray;
}

.o2>.e:after {
    background: gray;
}

.o3>.e:after {
    background: dimgray;
}

.wrapper {
    display: inline-block;
    flex: none;
    margin: -.4em .3em;
    width: 3em;
    height: 3em;
}

.o {
    border-style: solid;
    border-width: .06em;
    border-radius: 50%;
    width: 50%;
    height: 100%;
    margin: auto;
    margin-bottom: -100%;
    position: relative;
}

.o2 {
    transform: rotate(120deg);
}

.o3 {
    transform: rotate(240deg);
}

.e {
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: 0%;
    animation: orbit .9s linear infinite;
}

.o2>.e {
    animation-delay: -.3s;
}

.o3>.e {
    animation-delay: -.6s;
}

.e:after {
    display: block;
    content: '';
    width: .4em;
    height: .4em;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

@keyframes orbit {
    0% {
        top: 0%;
        left: 50%;
    }

    12.5% {
        top: 15%;
        left: 85%;
    }

    25% {
        top: 50%;
        left: 100%;
    }

    37.5% {
        top: 85%;
        left: 85%;
    }

    50% {
        top: 100%;
        left: 50%;
    }

    62.5% {
        top: 85%;
        left: 15%;
    }

    75% {
        top: 50%;
        left: 0%;
    }

    87.5% {
        top: 15%;
        left: 15%;
    }
}
</style>

<script>
export default {

}
</script>
